.background-color-approved {
  color: #00a000;
  background: rgba(0, 160, 0, 0.12);
}

.background-color-denied {
  color: #e64539;
  background: rgba(230, 69, 57, 0.12);
}

.background-color-sent {
  background: rgba(166, 169, 0, 0.12);
  color: #a6a900;
}
