.filter-icon-height-and-width {
  height: 23px;
  width: auto;
}

.select-all-icon-height-and-width {
  height: 16px;
  width: auto;
}

.height-1px {
  height: 1px;
}

.margin-bottom-60px {
  margin-bottom: 60px;
}

.trapezoid {
  border-top: 36px solid #f5f5f5;
  border-left: 24px solid transparent;
  border-right: 24px solid transparent;
  height: 0;
  width: 155px;
  border-radius: 48px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.rotate-animation {
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.height-2px {
  height: 2px;
}

.container-remaining-height {
  height: calc(100% - 250px);
}

.background-failed {
  background: rgba(230, 69, 57, 0.12);
}

.background-red-gradient {
  background: linear-gradient(
    90deg,
    rgba(230, 69, 57, 0) 0%,
    rgba(230, 69, 57, 0.12) 100%
  );
}

.grey-line {
  width: 100%;
  background: linear-gradient(
    90deg,
    hsla(0, 0%, 100%, 1) 0%,
    hsla(0, 0%, 58%, 1) 50%,
    hsla(0, 0%, 100%, 1) 100%
  );
}

.line {
  position: relative;
  background: repeating-linear-gradient(
    to right,
    white 0%,
    #00a000 25%,
    #00a000 50%,
    #00a000 75%,
    white 100%
  );
}

.border-bottom-2px-transparent {
  border-radius: 1px;
  border-bottom: 2px solid transparent;
}

.border-color-primary {
  border-color: #00a000;
}

.overflow-horizontal-scroll {
  overflow-x: scroll;
}

.overflow-horizontal-scroll::-webkit-scrollbar {
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none;
}

.overflow-horizontal-scroll::-webkit-scrollbar-track {
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none;
}

.overflow-horizontal-scroll::-webkit-scrollbar-thumb {
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none;
}

.max-width-550px {
  max-width: 550px;
}

.font-size-8px {
  font-size: 8px;
}

.box-shadow-secondary {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
}

.border-left-right-1px {
  border-right: 1px solid #f1f1f1;
  border-left: 1px solid #f1f1f1;
}

.display-none {
  display: none;
}

.patient-timeline-filter-icon-height {
  height: 18px;
}

.home-upload-icon-height {
  height: 16px;
}

.flex-basis-30-percentage {
  flex-basis: 30%;
}

.flex-basis-for-documents-card {
  flex-basis: 75%;
}

.white-space-no-wrap {
  white-space: nowrap;
}

.text-overflow-elilipsis {
  text-overflow: ellipsis;
}

.filter-icon-height-and-width:hover {
  background: lighten(#f44336, 10%);
  transition: all 0.5s;
}

.filter-icon-height-and-width:active {
  background: lighten(#f44336, 5%);
  transition: all 0.5s;
}

.width-75-percentage {
  width: 75%;
}

.home-add-documents-icon-size {
  height: 32px;
}

.document-card-timestamp-max-width {
  min-width: 65%;
}

.line-height-100-percentage {
  line-height: 100%;
}

.border-right-1px {
  border-right: 1px solid #f1f1f1;
}

.padding-bottom-for-last-timeline-card {
  padding-bottom: 50px;
}

.svg {
  width: 200px;
  height: 200px;
  transform: rotate(-90deg);
  overflow: initial;
}

.circle {
  strokewidth: 20px;
  fill: none;
}

.circle:nth-child(1) {
  stroke: #fff;
}

.circle:nth-child(2) {
  stroke: #f00;
  position: relative;
  z-index: 1;
}

.circle_box:nth-child(1) .circle:nth-child(2) {
  stroke-dashoffset: calc(100 * 6);
  stroke-dasharray: calc(100 * 6);
  stroke-dashoffset: calc((100 * 6) - ((100 * 6) * 50) / 100);
}

.circle_box:nth-child(2) .circle:nth-child(2) {
  stroke-dashoffset: calc(100 * 6);
  stroke-dasharray: calc(100 * 6);
  stroke-dashoffset: calc((100 * 6) - ((100 * 6) * 75) / 100);
  stroke: rgb(37, 224, 109);
}

.circle_box:nth-child(3) .circle:nth-child(2) {
  stroke-dashoffset: calc(100 * 6);
  stroke-dasharray: calc(100 * 6);
  stroke-dashoffset: calc((100 * 6) - ((100 * 6) * 40) / 100);
  stroke: rgb(227, 241, 25);
}

.circle_box {
  font-size: 36px;
  color: #fff;
  text-align: center;
}

.circle_box div {
  position: relative;
}

.circle_box span {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: rgb(109, 76, 76);
  font-size: 40px;
}

.severity-scale-size-top {
  height: 50%;
  width: 100%;
}

.severity-scale-size-bottom {
  height: 50%;
  width: 100%;
}

.border-radius-top {
  border-radius: 50% 50% 0 0;
}

.transform-origin-bottom {
  transform-origin: bottom;
}

.severity-scale-wrap-size {
  height: 32px;
  width: 32px;
  background: linear-gradient(180deg, #f1f1f1 50%, #fff 50%);
}

.severity-scale-value-circle-size {
  height: 18px;
  width: 18px;
}

.severity-scale-parent-circel-size {
  height: 40px;
  width: 40px;
  background: linear-gradient(180deg, #f1f1f1 50%, #fff 50%);
}

.patient-timeline-menu-floating-button-position-right {
  right: 16px;
}

.width-85-percentage {
  width: 85%;
}

.timeline-menu-item-min-width {
  min-width: fit-content;
}

.patient-timeline-upload-icon-gap {
  gap: 15px;
}

.vital-data-card-icon-size,
.fitness-data-icon-height {
  height: 16px;
}

.white-space-preline {
  white-space: pre-line;
}

.timeline-menu-scroll-bar::-webkit-scrollbar {
  height: 3px;
}

.timeline-menu-scroll-bar::-webkit-scrollbar-thumb {
  background: #e5e5e5;
  border-radius: 8px;
}

@media screen and (max-width: 350px) {
  .patient-timeline-upload-icon-gap {
    gap: 13px;
  }

  .flex-basis-for-documents-card {
    flex-basis: 80%;
  }

  .document-card-timestamp-max-width {
    min-width: 60%;
  }
}

@media screen and (min-width: 575px) {
  .patient-timeline-upload-icon-gap {
    gap: 20px;
  }

  .patient-timeline-filter-icon-height {
    height: 24px;
  }

  .home-upload-icon-height {
    height: 24px;
  }

  .filter-icon-height-and-width {
    height: 25px;
    width: auto;
  }

  .severity-scale-value-circle-size {
    height: 27px;
    width: 27px;
  }

  .severity-scale-parent-circel-size {
    height: 52px;
    width: 52px;
  }

  .severity-scale-wrap-size {
    height: 42px;
    width: 42px;
  }

  .patient-timeline-menu-floating-button-position-right {
    right: 36px;
  }
}

@keyframes lineH {
  0% {
    width: 0%;
    opacity: 0;
  }

  25% {
    width: 25%;
    opacity: 0.5;
  }

  50% {
    width: 50%;
    opacity: 1;
  }

  75% {
    width: 75%;
    opacity: 0.5;
  }

  100% {
    width: 100%;
    opacity: 0;
  }
}

.animated-gradient {
  height: 2px;
  position: absolute;
  background: repeating-linear-gradient(
    to right,
    white 0%,
    white 25%,
    #00a000 50%,
    white 75%,
    white 100%
  );
  width: 100%;
  background-size: 200% auto;
  background-position: 0 100%;
  animation: gradient 2s infinite;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
}

@keyframes gradient {
  0% {
    background-position: 0 0;
    opacity: 0.5;
  }

  25% {
    opacity: 0, 5;
  }

  50% {
    background-position: -100% 0;
    opacity: 1;
  }

  75% {
    opacity: 0.5;
  }

  100% {
    background-position: -200% 0;
    opacity: 0.5;
  }
}

.border-1px-F1F1F1 {
  border: 1px solid #f1f1f1;
}
