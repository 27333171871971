.background-color-black-with-opacity-light {
  background: rgba(0, 0, 0, 0.25);
}

.minimum-width-250px {
  min-width: 250px;
}

.minimum-height-250px {
  min-height: 250px;
}

.max-height-300px {
  max-height: 300px;
}

.width-auto {
  width: auto;
}

.switch-box {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.switch-box::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.z-index-101 {
  z-index: 101;
}

.z-index-102 {
  z-index: 102;
}
